import Invoice from "./Invoice";

export default function Daily_Account({ ...params }) {
    return <div>
        <Invoice {...params} entity="daily_account" type='daily_account'></Invoice>
    </div>
}




