'use client'

import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function useAlert() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity_, set_severity_] = React.useState('success')

  const showAlert = (msg, severity='success') => {
    set_severity_(severity)
    setMessage(msg);
    setOpen(true);

  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const alertComponent = (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity_} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );

  return { showAlert, alertComponent };
}
