import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography, List, ListItem, ListItemText, Divider, AppBar, Toolbar, Box } from '@mui/material';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Company from "./Company";
import BillTo from "./BillTo";
import Item from "./Item";
import Invoice from "./Invoice";
import Shipment from "./Shipment";
import PurchaseOrder from "./PurchaseOrder";
import DailyAccount from "./DailyAccount";
import { redirect } from "react-router-dom";


const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});
function Home(){
  window.location.href = ("/document/invoice/invoice");
  return <h1></h1>

}
export default function App({ children }) {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setToken(token);
    setLoading(false);
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <List component="nav" sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
        <ListItem button component={Link} to='/company'>
          <ListItemText primary='Company' />
        </ListItem>
        <Divider orientation="vertical" flexItem />
        <ListItem button component={Link} to='/type/bill_to'>
          <ListItemText primary='Bill To' />
        </ListItem>
        <ListItem button component={Link} to='/type/ship_from'>
          <ListItemText primary='Ship From' />
        </ListItem>
        <ListItem button component={Link} to='/type/ship_to'>
          <ListItemText primary='Ship To' />
        </ListItem>
        <ListItem button component={Link} to='/type/vendor'>
          <ListItemText primary='Vendors' />
        </ListItem>
        <ListItem button component={Link} to='/item'>
          <ListItemText primary='Items' />
        </ListItem>
        <Divider orientation="vertical" flexItem />
        <ListItem button component={Link} to='/document/invoice/first_quote'>
          <ListItemText primary='First Quote' />
        </ListItem>
        <ListItem button component={Link} to='/document/invoice/final_quote'>
          <ListItemText primary='Final Quote' />
        </ListItem>
        <ListItem button component={Link} to='/document/invoice/invoice'>
          <ListItemText primary='Invoice' />
        </ListItem>
        <ListItem button component={Link} to='/document/shipment/shipment'>
          <ListItemText primary='Shipment' />
        </ListItem>
        <ListItem button component={Link} to='/document/purchase_order/purchase_order'>
          <ListItemText primary='Purchase Order' />
        </ListItem>
        <ListItem button component={Link} to='/document/daily_account/daily_account'>
          <ListItemText primary='Daily Account' />
        </ListItem>
        <ListItem button component={Link} to='/document/daily_account/daily_receivable'>
          <ListItemText primary='Daily Receivable' />
        </ListItem>
      </List>
      <div>
        {/* Top Navbar */}
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Quick Invoicer
            </Typography>

          </Toolbar>
        </AppBar>

        {/* Main content */}
        <Box component="main" sx={{ p: 3 }}>
          <Routes>
          
            <Route path="/" Component={Home} />
            <Route path="/company" Component={Company} />
            <Route path="/type/:type" Component={BillTo} />
            <Route path="/item" Component={Item} />
            <Route path="/document/:entity/:type" Component={Invoice} >
              <Route path="/document/:entity/:type/:entity_edit_id" Component={Item} />
            </Route>
          </Routes>
        </Box>
      </div>
    </ThemeProvider>
  );
}
