// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice_viewer_container {
  height: 80vh;
  overflow-y: auto;
  overflow-x: auto;
}

.invoice_viewer {
  padding: 50px 50px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.341);
  width: 1080px !important;
  height: auto !important;
  margin: 10px 10px;
}
.invoice_viewer h1,
.invoice_viewer h2,
.invoice_viewer h3,
.invoice_viewer h4,
.invoice_viewer h5,
.invoice_viewer h6 {
  font-weight: bold;
  margin: 0px;
  padding-bottom: 5px;
}
.invoice_viewer .first_section,
.invoice_viewer .second_section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  border-top: 2px solid rgb(69, 69, 69);
  padding-top: 10px;
}
.invoice_viewer .first_section {
  grid-template-columns: 1fr 1fr;
  padding-top: 0;
}
.invoice_viewer .forth_section table {
  margin: auto;
}
.invoice_viewer .fifth_section {
  width: max-content;
  margin: auto;
}
.invoice_viewer .sixth_section {
  margin-top: 10px;
}
.invoice_viewer .total_price_ {
  width: max-content;
  margin: auto;
  margin-right: 150px;
  margin-top: 10px;
  font-weight: bold;
}
.invoice_viewer td {
  padding: 5px 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/invoice_viewer.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AACA;EACI,kBAAA;EACA,iDAAA;EACA,wBAAA;EACA,uBAAA;EACA,iBAAA;AAEJ;AAAI;;;;;;EAMI,iBAAA;EACA,WAAA;EACA,mBAAA;AAER;AACI;;EAEI,aAAA;EACA,kCAAA;EACA,cAAA;EACA,qCAAA;EACA,iBAAA;AACR;AACI;EACI,8BAAA;EACA,cAAA;AACR;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,kBAAA;EACA,YAAA;AAFR;AAKI;EACI,gBAAA;AAHR;AAKI;EACI,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AAHR;AAKI;EACI,2BAAA;AAHR","sourcesContent":[".invoice_viewer_container {\n    height: 80vh;\n    overflow-y: auto;\n    overflow-x: auto;\n}\n.invoice_viewer {\n    padding: 50px 50px;\n    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.341);\n    width: 1080px !important;\n    height: auto !important;\n    margin: 10px 10px;\n\n    h1,\n    h2,\n    h3,\n    h4,\n    h5,\n    h6 {\n        font-weight: bold;\n        margin: 0px;\n        padding-bottom: 5px;\n    }\n\n    .first_section,\n    .second_section {\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr;\n        grid-gap: 50px;\n        border-top: 2px solid rgb(69, 69, 69);\n        padding-top: 10px;\n    }\n    .first_section {\n        grid-template-columns: 1fr 1fr;\n        padding-top: 0;\n    }\n    .forth_section {\n        table {\n            margin: auto;\n        }\n    }\n\n    .fifth_section {\n        width: max-content;\n        margin: auto;\n    }\n\n    .sixth_section {\n        margin-top: 10px;\n    }\n    .total_price_ {\n        width: max-content;\n        margin: auto;\n        margin-right: 150px;\n        margin-top: 10px;\n        font-weight: bold;\n    }\n    td {\n        padding: 5px 0px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
